var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-4" }, [
    _c(
      "div",
      { staticClass: "hidden sm:block absolute top-0 right-0 pt-4 pr-4" },
      [
        _c(
          "button",
          {
            staticClass:
              "bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.onClose("canceled")
              },
            },
          },
          [
            _c("span", { staticClass: "sr-only" }, [_vm._v("Close")]),
            _vm._v(" "),
            _c("i", { staticClass: "bx bx-x" }),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sm:flex sm:items-start" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" },
        [
          _c(
            "h3",
            {
              staticClass: "text-lg leading-6 font-medium text-gray-900",
              attrs: { id: "modal-title" },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("confirmation")) + "\n      ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _c("p", { staticClass: "text-sm text-gray-500" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("delete-this")) + "\n        "
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-1" },
      [
        _c(
          "button",
          {
            staticClass:
              "mt-3 text-xs text-red-500 bg-red-100 font-medium flex items-center rounded-md p-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.toDelete("canceled")
              },
            },
          },
          [
            _vm._v("\n      " + _vm._s(_vm.$t("delete")) + "\n      "),
            _c("i", { staticClass: "bx bx-trash text-red-600" }),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "mt-3 text-xs text-gray-500 bg-gray-200 font-medium flex items-center rounded-md p-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.onClose("canceled")
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10",
      },
      [_c("i", { staticClass: "bx bx-shield-x text-red-600" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }