var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cursor-pointer",
      on: {
        click: function ($event) {
          return _vm.toDetail()
        },
      },
    },
    [
      _c("div", { staticClass: "space-x-4" }, [
        _c(
          "div",
          {
            staticClass:
              "border rounded-md border-gray-200 shadow-sm hover:shadow-md group",
          },
          [
            !_vm.data.attributes.image && !_vm.data.attributes.thumbnail
              ? _c("img", {
                  staticClass:
                    "h-60 object-cover w-full group-hover:opacity-70 rounded-md",
                  attrs: { src: require("assets/images/video.png") },
                })
              : _c("img", {
                  staticClass:
                    "h-60 object-cover w-full group-hover:opacity-70 rounded-md",
                  attrs: {
                    src: _vm.$tools.getFileUrl(
                      _vm.data.attributes.thumbnail
                        ? _vm.data.attributes.thumbnail
                        : _vm.data.attributes.image
                    ),
                  },
                }),
            _vm._v(" "),
            _c("div", { staticClass: "p-4" }, [
              _c("div", { staticClass: "flex items-center justify-between" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-xs border border-green-700 group-hover:bg-green-700 group-hover:text-white text-green-700 font-medium rounded-md py-1.5 px-3",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.data.attributes.coursecategory.data
                            ? _vm.data.attributes.coursecategory.data.attributes
                                .name
                            : ""
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.data.attributes && _vm.data.attributes.publish_date
                  ? _c(
                      "div",
                      { staticClass: "text-green-700 text-sm font-semibold" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$tools.getDate(
                                _vm.data.attributes.publish_date
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "text-green-700 text-sm font-semibold" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$tools.getDate(_vm.data.attributes.createdAt)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "my-4 text-gray-700 group-hover:text-green-700 font-semibold text-lg line-clamp-1",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.data.attributes.title) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-gray-600 text-sm line-clamp-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.data.attributes.description) +
                    "\n        "
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }