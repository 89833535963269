var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full pt-16 pb-12 flex flex-col bg-white" },
    [
      _c(
        "main",
        {
          staticClass:
            "flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8",
        },
        [
          _c(
            "div",
            { staticClass: "flex-shrink-0 flex justify-center" },
            [
              _c(
                "NuxtLink",
                { staticClass: "inline-flex", attrs: { to: "/" } },
                [
                  _c("span", { staticClass: "sr-only" }, [_vm._v("Workflow")]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "h-14 w-auto",
                    attrs: {
                      src: "/logo-with-name.svg",
                      alt: "Akfa university AIS",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "py-12" }, [
            _c("div", { staticClass: "text-center" }, [
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-semibold text-red-600 uppercase tracking-wide",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.error.statusCode) +
                      " error\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "h1",
                {
                  staticClass:
                    "mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl",
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.error.message) + ".\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.error.statusCode === 500
                ? _c("p", { staticClass: "mt-2 text-base text-gray-500" }, [
                    _vm._v(
                      "\n          Sorry, we will try to fix this issue as soon as possible.\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c(
                    "NuxtLink",
                    {
                      staticClass:
                        "text-base font-medium text-red-600 hover:text-red-500",
                      attrs: { to: "/" },
                    },
                    [
                      _vm._v("\n            Go back home"),
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v(" →"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }