var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass:
            "w-full focus:outline-none rounded-md py-3 px-5 flex items-center justify-between mt-6",
          class: _vm.isAnswerOpened
            ? "bg-green-700 text-white"
            : "bg-yellow-50",
          on: {
            click: function ($event) {
              return _vm.openAnswer()
            },
          },
        },
        [
          _c("div", [_vm._v(_vm._s(_vm.data.attributes.question))]),
          _vm._v(" "),
          _c("i", {
            staticClass: "bx text-xl",
            class: _vm.isAnswerOpened ? "bx-x" : "bx-plus",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "ease-out duration-300",
            "enter-class":
              "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
            "enter-to-class": "opacity-100 translate-y-0 sm:scale-100",
            "leave-active-class": "ease-in duration-200",
            "leave-class": "opacity-100 translate-y-0 sm:scale-100",
            "leave-to-class":
              "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isAnswerOpened,
                  expression: "isAnswerOpened",
                },
              ],
              staticClass: "bg-white p-5 text-gray-500 text-xs",
            },
            [_vm._v("\n      " + _vm._s(_vm.data.attributes.answer) + "\n    ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }