var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "py-8 px-3 relative sm:px-8" },
      [
        _c(
          "div",
          {
            staticClass:
              "absolute right-4 top-4 text-center p-1 rounded-md hover:bg-gray-100 cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.onClose("canceled")
              },
            },
          },
          [_c("i", { staticClass: "bx bx-x text-2xl text-gray-700" })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex justify-between" }, [
          _c("div", { staticClass: "flex justify-between items-center mb-3" }, [
            _c("div", { attrs: { id: "signin" } }, [
              _c(
                "button",
                {
                  staticClass:
                    "text-xl font-medium text-gray-500 focus:outline-none",
                  class: [
                    _vm.status === "sign-in"
                      ? "border-b border-green-600 text-green-600"
                      : "hover:text-green-600 text-gray-600",
                  ],
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.$t("login")) + "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "register" } }, [
              _c(
                "button",
                {
                  staticClass:
                    "text-xl font-medium text-gray-500 ml-6 focus:outline-none",
                  class: [
                    _vm.status === "register"
                      ? "border-b border-green-600 text-green-600"
                      : "hover:text-green-600  text-gray-600",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.openRegister()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("registration")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("ValidationObserver", {
          ref: "observer",
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ passes, invalid }) {
                return [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return passes(_vm.tryToLogIn)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-1" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "required|phoneOrEmail",
                              name: "Username",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ valid, errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.auth.identifier,
                                            expression: "auth.identifier",
                                          },
                                        ],
                                        staticClass:
                                          "focus:outline-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                        class:
                                          valid || _vm.authError.length === 0
                                            ? "border-green-600 focus:ring-green-600 focus:border-green-600"
                                            : "border-red-600 focus:ring-red-600 focus:border-red-600",
                                        attrs: {
                                          name: "text",
                                          type: "text",
                                          autocomplete: "text",
                                          placeholder: "Phone number",
                                          state: errors[0]
                                            ? false
                                            : valid
                                            ? true
                                            : null,
                                          required: "",
                                        },
                                        domProps: {
                                          value: _vm.auth.identifier,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.auth,
                                              "identifier",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isEmail
                        ? _c(
                            "div",
                            { staticClass: "mt-1" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  rules: "required|min:6",
                                  name: "password",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ valid, errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.auth.password,
                                                expression: "auth.password",
                                              },
                                            ],
                                            staticClass:
                                              "focus:outline-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                            class:
                                              valid ||
                                              _vm.authError.length === 0
                                                ? "border-green-600 focus:ring-green-600 focus:border-green-600"
                                                : "border-red-600 focus:ring-red-600 focus:border-red-600",
                                            attrs: {
                                              name: "password",
                                              type: "password",
                                              placeholder: _vm.$t("password"),
                                              state: errors[0]
                                                ? false
                                                : valid
                                                ? true
                                                : null,
                                              required: "",
                                            },
                                            domProps: {
                                              value: _vm.auth.password,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.auth,
                                                  "password",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "mt-3 w-full flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none",
                          attrs: { disabled: invalid, type: "submit" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("sign-in")) +
                              "\n          "
                          ),
                          _vm.loading
                            ? _c("vue-loaders", {
                                attrs: {
                                  name: "ball-beat",
                                  color: "white",
                                  scale: "0.5",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("div", { staticClass: "flex items-center justify-center mt-2" }, [
          _c("p", { staticClass: "text-gray-600 text-sm font-medium" }, [
            _vm._v(_vm._s(_vm.$t("do-not-have-an-account"))),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "ml-2 text-yellow-600 text-sm font-medium focus:outline-none",
              on: { click: _vm.openRegister },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("register")) + "\n      ")]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }