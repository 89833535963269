export { default as Categories } from '../../components/Categories.vue'
export { default as ChatBody } from '../../components/ChatBody.vue'
export { default as Experts } from '../../components/Experts.vue'
export { default as Faq } from '../../components/Faq.vue'
export { default as HeaderCrud } from '../../components/HeaderCrud.vue'
export { default as LineChart } from '../../components/LineChart.vue'
export { default as MainRegister } from '../../components/MainRegister.vue'
export { default as News } from '../../components/News.vue'
export { default as Pagination } from '../../components/Pagination.vue'
export { default as Prices } from '../../components/Prices.vue'
export { default as Sidebar } from '../../components/Sidebar.vue'
export { default as Social } from '../../components/Social.vue'
export { default as StarRating } from '../../components/StarRating.vue'
export { default as VideoCard } from '../../components/VideoCard.vue'
export { default as MainLoading } from '../../components/main-loading.vue'
export { default as ModalsConsultantWarning } from '../../components/modals/consultant-warning.vue'
export { default as ModalsDelete } from '../../components/modals/delete.vue'
export { default as ModalsFinishChat } from '../../components/modals/finish-chat.vue'
export { default as ModalsImageEditor } from '../../components/modals/image-editor.vue'
export { default as ModalsRegisterType } from '../../components/modals/register-type.vue'
export { default as ModalsRegister } from '../../components/modals/register.vue'
export { default as ModalsSendMedia } from '../../components/modals/send-media.vue'
export { default as ModalsSignin } from '../../components/modals/signin.vue'
export { default as ModalsSuccessful } from '../../components/modals/successful.vue'
export { default as CoreFooter } from '../../components/core/footer.vue'
export { default as CoreLangSwitcher } from '../../components/core/lang-switcher.vue'
export { default as CoreMobileMenu } from '../../components/core/mobile-menu.vue'
export { default as CoreNavbar } from '../../components/core/navbar.vue'
export { default as CoreYandexMap } from '../../components/core/yandex-map.vue'
export { default as UiBaseDropdown } from '../../components/ui/BaseDropdown.vue'
export { default as UiBaseMenu } from '../../components/ui/BaseMenu.vue'
export { default as UiBaseModal } from '../../components/ui/BaseModal.vue'
export { default as UiBaseRangeInput } from '../../components/ui/BaseRangeInput.vue'
export { default as UiBaseSwiper } from '../../components/ui/BaseSwiper.vue'
export { default as UiBreadcrumbs } from '../../components/ui/Breadcrumbs.vue'
export { default as UiError } from '../../components/ui/Error.vue'
export { default as UiPagination } from '../../components/ui/Pagination.vue'
export { default as UiSkeleton } from '../../components/ui/Skeleton.vue'
export { default as UiSlideOver } from '../../components/ui/SlideOver.vue'
export { default as UiSwiperOptions } from '../../components/ui/swiper-options.js'
export { default as SwipersCategoriesSwiper } from '../../components/swipers/categories-swiper.vue'
export { default as SwipersExpertsSwiper } from '../../components/swipers/experts-swiper.vue'
export { default as SwipersNewsSwiper } from '../../components/swipers/news-swiper.vue'
export { default as SwipersPartnersSwiper } from '../../components/swipers/partners-swiper.vue'
export { default as SwipersPriceSwiper } from '../../components/swipers/price-swiper.vue'
export { default as SwipersNewsSwipersNewSwipers } from '../../components/swipers/news-swipers/new-swipers.vue'
export { default as SwipersNews } from '../../components/swipers/news-swipers/news.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
