var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "lg:flex grid grid-cols-3 items-center justify-center lg:gap-7 gap-4 my-6",
    },
    _vm._l(_vm.data, function (category, index) {
      return _c(
        "button",
        {
          key: index,
          staticClass:
            "rounded-md flex items-center focus:outline-none py-1.5 px-4",
          class:
            _vm.$route.query.category &&
            parseInt(_vm.$route.query.category) === category.id
              ? "bg-green-700 text-white"
              : "border border-green-700 text-green-700 hover:text-white hover:bg-green-700",
          on: {
            click: function ($event) {
              return _vm.toChange(category)
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(category.attributes ? category.attributes.name : "") +
              "\n  "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }